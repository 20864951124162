'use client'

// React.js Specific Imports
import { useEffect, useState } from 'react'

// Actions
import { WhiteboardSessionContext } from '@/actions/interactive-tools/openWhiteboard'

export type UseWhiteboardSessionOptions =
  | { mode: 'popup', ctx?: WhiteboardSessionContext }
  | { mode: 'embedded', ctx?: WhiteboardSessionContext, learningObjectId?: string }

export type WhiteboardSession =
  | { authenticated: false, whiteboardUrl: undefined }
  | { authenticated: true, whiteboardUrl: URL }

// TODO: fix mode: 'embedded' to work with react rendering in embedded reader
export default function useWhiteboardSession(opts: UseWhiteboardSessionOptions): WhiteboardSession {
  const { ctx, mode } = opts
  //console.log('useWhiteboardSession: ctx', ctx)

  const [whiteboardSession, setWhiteboardSession] = useState<WhiteboardSession>({ authenticated: false, whiteboardUrl: undefined })

  useEffect(() => {
    if (typeof window !== 'undefined' && ctx != null) {
      const completeOpenWhiteboard = async () => {
        const { token, whiteboardEndpoint, sessionLoginEndpoint } = ctx

        try {
          const sessionLoginResult = await fetch(sessionLoginEndpoint, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })

          if (sessionLoginResult.ok) {
            const sessionLoginResultJson = await sessionLoginResult.json()

            let whiteboardUrl = new URL(whiteboardEndpoint)

            switch (mode) {
              case 'embedded': {
                whiteboardUrl = new URL(`/whiteboard/whiteboard/${opts.learningObjectId}`, whiteboardEndpoint)
                whiteboardUrl.searchParams.append('isEmbedded', '1')
                break
              }

              case 'popup': {
                window.open(whiteboardUrl, 'whiteboard', 'width=1200,height=600')
                break
              }

              default: {
                break
              }
            }

            setWhiteboardSession({
              authenticated: true,
              whiteboardUrl: whiteboardUrl,
            })
          } else {
            // noop for failed whiteboard session logins
            console.error(`useWhiteboardSession: ${sessionLoginResult.status} ${sessionLoginResult.statusText} - failed to pre-authenticate to whiteboard`)
          }
        } catch (e) {
          // network, CORS or aborts
          console.error('useWhiteboardSession: error calling sessionLogin endpoint:', e)
        }
      }
      completeOpenWhiteboard()
    }
  }, [ctx, mode])

  return whiteboardSession
}
