'use client'

import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import Image from 'next/image'
import { useTranslations } from 'next-intl'
import { Modal } from '@edwin-edu/ui/client'
import { Button, Flex, H1, H2, Text } from '@edwin-edu/ui/server'
import EnhanceIcon from '@/public/home/tutorial-enhance.svg'
import IntroduceIcon from '@/public/home/tutorial-introduce.svg'
import BuildIcon from '@/public/home/tutorial-build.svg'
import ReplayIcon from '@/public/home/tutorial-replay.svg'
import CheckMarkIcon from '@/public/icon-check-circled.svg'
import { DASHBOARD_PATH, READER_PATH } from '@/constants'
import { useAuth } from '@/components/AuthProvider'
import { getUserAccountType } from '@/utils/user'
import styles from './OnboardingTeacherModal.module.scss'

// enum for teacherModalLOsViewed profile array
const CardNames = {
  QUICKSTART_TEACHER: 'Quickstart_Teacher',
  INVITE: 'Invite',
  QUICKSTART_STUDENT: 'Quickstart_Student',
}

const OnboardingTeacherModal = () => {
  const router = useRouter()
  const t = useTranslations()

  const { user, update } = useAuth()
  const { isStudent } = getUserAccountType(user)
  const { hideHomeTeacherModal, teacherModalLOsViewed } = user?.profile ?? {}

  const [isMounted, setIsMounted] = useState(false)
  const [userDismissedModal, setUserDismissedModal] = useState(false)

  // Note these hardcoded LO cms ids will work only on envs using prod db
  const handleCardClick = (
    loCmsId: string,
    cardName: string,
    navigateToDashboard = false,
  ) => {
    setUserDismissedModal(true)
    if (!(teacherModalLOsViewed ?? [])?.includes(cardName)) {
      update({
        profile: {
          hideHomeTeacherModal: false,
          teacherModalLOsViewed: (teacherModalLOsViewed ?? [])?.concat([
            cardName,
          ]),
        },
      })
    }
    if (navigateToDashboard) {
      router.push(DASHBOARD_PATH)
    } else {
      router.push(`${READER_PATH}/${loCmsId}`)
    }
  }

  const handleDismissClick = () => {
    setUserDismissedModal(true)
  }

  const handleDontShowAgainClick = () => {
    setUserDismissedModal(true)
    update({
      profile: {
        hideHomeTeacherModal: true,
      },
    })
  }

  const isOpen =
    hideHomeTeacherModal !== true &&
    !userDismissedModal

  const quickstartTeacherLOViewed = (teacherModalLOsViewed || [])?.includes(
    CardNames.QUICKSTART_TEACHER,
  )
  const inviteLOViewed = (teacherModalLOsViewed || [])?.includes(
    CardNames.INVITE,
  )
  const quickstartStudentLOViewed = (teacherModalLOsViewed || [])?.includes(
    CardNames.QUICKSTART_STUDENT,
  )

  const ReplayButton = (
    <>
      <span className="absolute top-[-12px] right-[-12px]">
        <Image data-test="NewTeacherModal-CheckMarkIcon-image" src={CheckMarkIcon} alt="check mark icon" />
      </span>
      <Flex
        className="absolute top-[105px] left-[66px]"
        direction="column"
        alignItems="center"
        gap={1}
      >
        <Image data-test="NewTeacherModal-replay-icon-image" src={ReplayIcon} alt="check mark icon" />
        <Text variant="tag" fontWeight={700}>{t('common.replay')}</Text>
      </Flex>
    </>
  )

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (isStudent || !isMounted) {
    return null
  }

  return (
    <Modal open={isOpen}>
      <Flex
        data-test="onboarding-teacher-modal"
        className="w-[680px]"
        direction="column"
        alignItems="center"
        gap={4}
      >
        <H1 className="leading-tight" margin={false} color="primary" textAlign="center">
          {t('home.tutorial.welcome')}
        </H1>
        <H2 className="mb-3 font-semibold leading-snug w-[440px]" margin={false} textAlign="center">
          {t('home.tutorial.description')}
        </H2>
        <Flex justifyContent="center" gap={10}>
          <button
            className={styles.card}
            onClick={() =>
              handleCardClick('28627', CardNames.QUICKSTART_TEACHER)
            }
          >
            <CardTitle>
              {t('home.tutorial.quickstart')}
            </CardTitle>
            <CardSubTitle>
              {t('home.tutorial.teachers')}
            </CardSubTitle>
            <div className={`pt-0 mx-auto ${quickstartTeacherLOViewed ? 'opacity-25' : ''}`}>
              <Image data-test="tutorial-enhance-icon-image" src={EnhanceIcon} alt="tutorial enhance icon" />
            </div>
            {quickstartTeacherLOViewed && ReplayButton}
          </button>
          <button
            className={styles.card}
            onClick={() => handleCardClick('', CardNames.INVITE, true)}
          >
            <CardTitle>
              {t('home.tutorial.invite1')}
            </CardTitle>
            <CardSubTitle>
              {t('home.tutorial.invite2')}
            </CardSubTitle>
            <div className={`pt-1 mx-auto ${inviteLOViewed ? 'opacity-25' : ''}`}>
              <Image data-test="tutorial-build-icon-image" src={BuildIcon} alt="tutorial build icon" />
            </div>
            {inviteLOViewed && ReplayButton}
          </button>
          <button
            className={styles.card}
            onClick={() =>
              handleCardClick('24947', CardNames.QUICKSTART_STUDENT)
            }
          >
            <CardTitle>
              {t('home.tutorial.quickstart')}
            </CardTitle>
            <CardSubTitle>
              {t('home.tutorial.students')}
            </CardSubTitle>
            <div className={`pt-2 mx-auto ${quickstartStudentLOViewed ? 'opacity-25' : ''}`}>
              <Image data-test="tutorial-introduce-icon-image" src={IntroduceIcon} alt="tutorial introduce icon" />
            </div>
            {quickstartStudentLOViewed && ReplayButton}
          </button>
        </Flex>
        <Button
          variant="unstyled"
          className="mt-2"
          onClick={() => handleDismissClick()}
          data-test="home-tutorial-dismiss-button"
        >
          <Text variant="small" className="font-semibold underline">
            {t('home.tutorial.dismiss')}
          </Text>
        </Button>
        <Button
          variant="unstyled"
          onClick={() => handleDontShowAgainClick()}
          data-test="home-tutorial-dont-show-again-button"
        >
          <Text variant="tag">
            {t('home.tutorial.dontShowAgain')}
          </Text>
        </Button>
      </Flex>
    </Modal>
  )
}

export default OnboardingTeacherModal

const CardTitle = ({ children }: React.PropsWithChildren) => (
  <H2 className="leading-7" margin={false} color="primary">
    {children}
  </H2>
)

const CardSubTitle = ({ children }: React.PropsWithChildren) => (
  <H2 className="mb-3 font-normal leading-6" margin={false} color="primary">
    {children}
  </H2>
)
