'use client'

import { FC, useState } from 'react'
import Image, { ImageProps } from 'next/image'

type Fallback = ImageProps['src']
type Props = ImageProps & { fallback: Fallback }

export const ImageWithFallback: FC<Props> = ({ fallback, alt, src, ...props }) => {
  const [isError, setIsError] = useState(false)

  const handleError = () => {
    if (!isError) {
      setIsError(true)
    }
  }

  return (isError || !src)
    ? fallback
      ? <Image
        unoptimized={true}
        data-test="fallback-image"
        alt={`Fallbackfor ${alt}`}
        src={fallback}
        {...props}
      />
      : null
    : (
      <Image
        unoptimized={true}
        data-test="image-with-fallback-image"
        alt={alt}
        src={src}
        onError={handleError}
        {...props}
        style={{ display: isError ? 'none' : 'block' }} // Hides the broken image
      />
    )
}
