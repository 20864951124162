'use client'

import React, { useTransition } from 'react'
import { initializeApp } from 'firebase/app'
import { User as FirebaseUser, getAuth, onIdTokenChanged } from 'firebase/auth'
import { Claims, filterStandardClaims } from 'next-firebase-auth-edge/lib/auth/claims'
import { User } from '@/queries/user/types'
import { getUser } from '@/actions/user/getUser'
import { updateUser } from '@/actions/user/updateUser'
import { FetchOptions } from '@/services'

export type ServerUser = FirebaseUser & {
  emailVerified: boolean;
  customClaims: Claims;
}

type AuthContextValue = {
  user?: User;
  serverUser?: ServerUser;
  update: (updated: Partial<User>, options?: FetchOptions) => void;
}

const AuthContext = React.createContext<AuthContextValue>({
  user: undefined,
  serverUser: undefined,
  update: () => { },
})

export const useAuth = () => React.useContext(AuthContext)

export const AuthProvider = ({
  user,
  serverUser,

  firebaseAPIKey,
  firebaseAuthDomain,
  firebaseProjectId,
  firebaseAppId,

  children,
}: {
  user?: User;
  serverUser?: ServerUser;

  firebaseAPIKey: string;
  firebaseAuthDomain: string;
  firebaseProjectId: string;
  firebaseAppId: string;

  children: React.ReactNode
}) => {
  const firebaseConfig = {
    apiKey: firebaseAPIKey,
    authDomain: firebaseAuthDomain,
    projectId: firebaseProjectId,
    appId: firebaseAppId,
  }

  const [updatedServerUser, setUpdatedServerUser] = React.useState<ServerUser | undefined>(serverUser)
  const [optimisticUser, setOptimisticUser] = React.useOptimistic<User | undefined, User | undefined>(
    user,
    (prev, updated) => ({ ...prev, ...updated })
  )

  const [isPending, startTransition] = useTransition()

  const handleIdTokenChanged = async (firebaseUser: FirebaseUser | null) => {
    if (firebaseUser) {
      const idTokenResult = await firebaseUser.getIdTokenResult()

      // Sets authenticated user cookies
      await fetch('/api/login', {
        headers: {
          Authorization: `Bearer ${idTokenResult.token}`,
        },
      })

      const user = await getUser()

      startTransition(() => {
        setOptimisticUser(user)
        setUpdatedServerUser({
          ...firebaseUser,
          customClaims: filterStandardClaims(idTokenResult.claims),
        })
      })

      return
    }

    // Removes authenticated user cookies
    await fetch('/api/logout')

    startTransition(() => {
      setOptimisticUser(undefined)
      setUpdatedServerUser(undefined)
    })
  }

  React.useEffect(() => {
    try {
      onIdTokenChanged(getAuth(), handleIdTokenChanged)
    } catch (error) {
      initializeApp(firebaseConfig)
    }
  }, [])

  const update = async (updated: Partial<User>, options?: FetchOptions) => {
    try {
      React.startTransition(() => {
        setOptimisticUser({
          ...user,
          ...updated,
          profile: {
            ...user?.profile,
            ...updated.profile,
          },
          state: {
            ...user?.state,
            ...updated.state,
          }
        })
      })
      await updateUser({ ...updated }, options)
    } catch (error) {
      console.error('Error updating in user provider', error)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: optimisticUser,
        serverUser: updatedServerUser,
        update,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
