import { User } from '@/queries/user/types'

export const getUserAccountType = (user?: User) => {
  const accountType = user?.accountType
  const role = user?.roles?.[0]

  const isAdmin = role === 'Admin'
  const isTeacher = role === 'Teacher'
  const isStudent = role === 'Student'
  const isTransform = accountType === 'Transform' || role === 'Admin'
  const isEmpower = accountType === 'Empower'
  const isEngage = accountType === 'Engage'

  return {
    isAdmin,
    isTeacher,
    isStudent,
    isTransform,
    isEmpower,
    isEngage,
  }
}
