'use client'

// Utilities, Globals, Constants
import { parseRecentlyViewedDate } from '@/utils/dates'

// Styles
import styles from './RecentlyViewedRow.module.scss'

export const RecentlyViewedDate = ({ date }: { date: string }) => {
  return (
    <span className={styles.panelDate}>
      {date && parseRecentlyViewedDate(new Date(date))}
    </span>
  )
}